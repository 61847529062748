.modal {
    position: absolute !important;
    z-index: 50 !important;
    top: 5rem;
    overflow: auto;
    background-color: #1F152E;
    width: 400px;

    @media (max-width: 768px) {
        top: 5rem; } }


.signinModalWrapper {
    display: flex;
    max-height: 90vh; }

.modal_content {
    position: relative;
    background-color: #1F152E;
    padding: 1.25rem 1.875rem;
    height: fit-content;
    overflow: auto;
    border-radius: 0.313rem; }

@media screen and (max-width : 768px) {
    .modal_content {
        max-width: 100%;
        height: unset; }

    .modal {
        width: 100%;
        position: fixed !important;
        left: 0;
        height: 100%; }


    .signinModalWrapper {
        display: flex;
        height: 100%;
 } }        //max-height: 100vh



.close {
    position: absolute;
    top: 0.938rem;
    cursor: pointer;
    right: 0.938rem;
    img {
        height: 1.5rem;
        width: 1.5rem; } }

.loaderPad {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffffa2;

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%; } }


.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
    color: #F2F6F9;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    h3 {
        font-size: 16px;
        font-weight: 500; }

    img {
        max-width: 120px; } }
.signInOptions {
    margin-top: 1.188rem; }


.walletOptions {
    display: flex;
    justify-content: center;
    padding: 16px 24px;
    align-items: flex-start;
    gap: 14px;
    align-self: stretch;
    border-radius: 8px;
    border: 2px solid #04DCFD;
    color: #F2F6F9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%; }

.option {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.813rem;
    padding: 0.625rem 0.75rem;
    font-weight: 500;
    border-radius: 0.313rem;
    justify-content: space-between;
    //box-shadow: rgba(100, 100, 111, 0.2) 0px 0.438rem 1.813rem 0px
    transition: all 0.2s;
    color: #3C3C3C;
    img {
        max-height: 2rem;
        max-width: 2rem; } }

.separator {
    margin-top: 2.5rem; }
.or {
    color:  #F2F6F9;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
    text-align: center; }


.socialLogin {
    margin-top: 2.125rem;
    display: flex;
    justify-content: center;
    column-gap: 0.625rem; }
.logo {
    border-radius: 0.313rem;
    cursor: pointer;
    padding: 0.625rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 0.438rem 1.813rem 0px;
    transition: all 0.2s;
    img {
        max-height: 1.5rem;
        max-width: 1.5rem; }
    &:hover {
        box-shadow: rgba(66, 66, 76, 0.2) 0px 0.625rem 2.5rem 0px; } }
